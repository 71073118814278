const UNIVERSAL = {
  BASEURL: "https://login.alphatechgateway.com/rest/api/",
  USERBASEURL: "https://bulkuser.alphatechgateway.com/rest/api/",
  ADMINBASEURL: "http://34.131.45.152:32806/rest/api/",
  USERBASEURL2: "https://adminpanel.alphatechgateway.com/rest/api/",
};


export default UNIVERSAL;



